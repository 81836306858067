import React from 'react';
import Layout from '../../components/layout';
import Image from '../../atoms/image';
import SEO from '../../components/seo';
import { titles, descriptions } from '../../const/metaData';
import {
  BoldText,
  LineBreak,
  Wrapper,
  TextContainer,
  ImageContainer,
  ImageContent,
} from '../../styles/commonStyles';
import { Link } from 'gatsby';
import { paths } from '../../const/paths';

const FAQs: React.FunctionComponent = () => {
  return (
    <Layout>
      <SEO title={titles.faqs} description={descriptions.faqs} />
      <Wrapper>
        <TextContainer>
          <h2>FAQs</h2>
          <LineBreak />
          <BoldText>We’re closing Bó.</BoldText>
          <p>
            We have made the decision to wind Bó down. Don’t worry, your account
            will continue to work, your money is safe and we will give you at
            least 60 days’ notice before we close your account. Until we close
            your account there will be no changes to Bó or how your account or
            card works day to day. If there’s anything we can help you with
            please contact our Support Team via the app or via{' '}
            <a href="mailto:bosupport@natwest.com">email</a>.
          </p>
          <LineBreak />
          <BoldText>WINDING DOWN BÓ</BoldText>
          <BoldText>What should I do with the money in my Bó account?</BoldText>
          <p>
            You can transfer your money out of your Bó account to another
            account, or spend your remaining balance via your Bó Visa debit
            card. You may also want to start using a different debit card for
            repeating payments.
          </p>
          <BoldText>Is my money still safe?</BoldText>
          <p>
            Yes. Any money in your Bó account is protected up to{' '}
            <a href="https://wearebo.co.uk/fscs/">£85,000</a> (if you have
            accounts with both Bó and NatWest, the maximum you'd be able to
            claim across all accounts combined is £85,000).
          </p>
          <BoldText>How long before you close the account?</BoldText>
          <p>
            We will give you at least{' '}
            <a href="https://wearebo.co.uk/terms-and-conditions/">
              60 days notice
            </a>{' '}
            before we close your account but in the meantime you can move your
            money to another account, or keep using your Bó Visa card until your
            balance hits zero.
          </p>
          <BoldText>Will you still open new accounts?</BoldText>
          <p>No, we will not open any new Bó accounts.</p>
          <BoldText>What about the money in my Piggy Bank?</BoldText>
          <p>
            You will need to transfer this money back into your main Bó account,
            and then from here transfer it out of Bó into another account.
          </p>
          <BoldText>Will my Bó card still work?</BoldText>
          <p>
            Yes. Your account and card will continue to work, your money is safe
            and we will give you at least 60 days notice before we close your
            account.
          </p>

          <LineBreak />
          <BoldText>ABOUT US</BoldText>
          <BoldText>Is Bó a bank?</BoldText>
          <p>Yes, Bó is part of NatWest.</p>
          <BoldText>
            Is Bó part of the Financial Service Compensation Scheme?
          </BoldText>
          <p>
            Yes. The money in your Bó account is protected up to a total of
            £85,000 by the Financial Services Compensation Scheme, the UK's
            deposit guarantee scheme. If you have accounts with both Bó and
            NatWest, the maximum you'd be able to claim across all accounts
            combined is £85,000.
          </p>
          <BoldText>Is my data safe with Bó?</BoldText>
          <p>
            Yes. Keeping your personal data safe and secure is really important
            to us. We will never sell your data to a third party or spam you.
          </p>
          <p>
            If you want to know more about how we store and use your information
            read our <Link to={paths.privacyFull}>privacy policy</Link>.
          </p>
          <LineBreak />
          <BoldText>USING MY ACCOUNT</BoldText>
          <BoldText>How do I add money to my Bó account?</BoldText>
          <p>
            You can add money to your account by bank transfer from another
            account, though please check the bank you're sending money from is
            part of the UK Faster Payments scheme (most are).
          </p>
          <BoldText>How can I check my balance?</BoldText>
          <p>You can find your available balance on the app's home screen.</p>
          <p>
            We always show your balance in real-time so you know exactly how
            much money you have.
          </p>
          <BoldText>Can I pay cash into Bó?</BoldText>
          <p>
            You can’t pay cash into your Bó account. However, if you deposit
            cash into another bank account you can then make a transfer into
            your Bó account.
          </p>
          <BoldText>Can I get my benefits paid into Bó?</BoldText>
          <p>
            Unfortunately, as benefits are mostly paid via the BACS system, you
            can’t have your benefits paid directly into Bó.
          </p>
          <p>
            The good thing about Bó being a second current account, is that you
            can have your benefits paid into your main bank account and then
            transfer, what you have left after paying any bills, into your Bó
            account and then Bó can help keep track of your day to day spending.
          </p>
          <BoldText>Does Bó have fees?</BoldText>
          <p>
            Making cash withdrawals from ATMs in the UK, EU or anywhere else in
            the world is free when you use Bó. However, you may be charged by
            the cash machine’s operator.
          </p>
          <p>
            If you lose or damage your card, we’ll replace it free of charge the
            first two times. But if you lose or damage it again before it
            naturally expires, we’ll charge you £5 each time to replace it.
          </p>
          <p>
            If your card is stolen or you need the name changed (e.g. if you get
            married), we’ll always send you a new card free of charge.
          </p>
          <BoldText>Do I need to tell you when I go abroad?</BoldText>
          <p>
            No, you don't need to tell us, your card will work just fine
            overseas. You can use it anywhere you see the Visa logo.
          </p>
          <p>
            We won't charge you extra for spending on your card overseas, you
            get the Visa exchange rate that we get.
          </p>
          <p>
            Keep an eye out for additional charges when overseas. Some shops and
            restaurants offer dynamic currency conversion. This is where you can
            choose to pay in pounds, but it means the retailer's bank is doing
            the conversion, and it's often a very poor exchange rate.
          </p>
          <p>
            You may also face additional fees at cash machines too. In some
            countries these are unavoidable, so it's best to try and minimise
            the number of cash withdrawals you make.
          </p>
          <BoldText>
            Are there any limits on how much money I can have in my account?
          </BoldText>
          <p>
            The maximum you can have in your Bó account is £20,000. The maximum
            you can have in your Piggy Bank is £20,000. The maximum payment you
            can make is £5,000 and the most you can spend on your card in one go
            is £3,000. You can withdraw up to £1,000 in cash on a 30-day rolling
            period following the date of your first withdrawal. The most that
            you can withdraw per day is £500.
          </p>
          <BoldText>How do I close my Bó account?</BoldText>
          <p>
            If you want to close your account, head to the 'Account' tab in the
            app, then select 'Your details'. Scroll all the way down until you
            see the 'Close account' button.
          </p>
          <BoldText>Can I get statements for a closed account?</BoldText>
          <p>
            Absolutely and it’s free of charge. You can request statements
            (transaction history) up to five years after closing your account.
            So, if you make the request four years after your account is closed,
            you’ll be able to get the transaction history for the last year that
            your account was open. Please email{' '}
            <a href="mailto:bosupport@natwest.com">bosupport@natwest.com</a> and
            tell us the sort code and account number of your closed account.
            You’ll also need to provide a copy of your ID (e.g. Driving License
            or Passport). We’ll send your transaction history to you by email as
            a PDF.
          </p>
          <BoldText>
            What’s the difference between BACS and Faster Payments?
          </BoldText>
          <p>
            BACS payments are an electronic system that makes payments from one
            bank account to another. They’re mainly used for Direct Debits and
            usually take around 3 working days to clear. Bó doesn’t currently
            support BACS payments but we are looking into introducing them in
            the future.
          </p>
          <p>
            Faster Payments is a scheme in the UK that allows you to transfer
            money between bank accounts in typically a few seconds. Bó currently
            uses Faster Payments. This means that to add money to your account,
            you need to make a transfer from another bank account and it should
            arrive almost instantly into Bó.
          </p>
          <LineBreak />
          <BoldText>YOUR DEBIT CARD</BoldText>
          <BoldText>Is my card contactless?</BoldText>
          <p>
            Yes, your Bó debit card is fully contactless, plus you'll get
            instant alerts every time you spend. If it's the first time you're
            using the card or if you're spending more than £30, you'll need to
            use chip and PIN.
          </p>
          <BoldText>Where can I use my debit card?</BoldText>
          <p>
            You can use your card anywhere Visa is accepted: online, in shops
            and at cash machines in the UK and abroad.
          </p>
          <BoldText>How do I lock my card?</BoldText>
          <p>
            If you want to lock your card tap on the 'Account' tab, go to 'Card
            settings' and then press the 'Lock card' button.
          </p>
          <BoldText>My card's been stolen. What do I do?</BoldText>
          <p>
            Please cancel it immediately: go to the 'Account' tab, then 'Card
            settings' and follow the prompts. We'll make sure no one can spend
            on your old card, and send you a replacement.
          </p>
          <p>
            If you don’t have access to the app, please click{' '}
            <Link to={paths.emergencyAccess}>here</Link> to lock your card.
          </p>
          <BoldText>I've lost my card. What do I do?</BoldText>
          <p>
            If you’ve lost your card, you can lock it in app immediately. Go to
            the 'Account' tab, then 'Card settings' and follow the prompts (if
            it does turn up you can then unlock it again from the app).
          </p>
          <BoldText>I've lost my phone and card. What do I do?</BoldText>
          <p>
            If you’ve lost your phone and card, please click{' '}
            <Link to={paths.emergencyAccess}>here</Link> to lock your card.
          </p>
          <BoldText>How do I change my PIN?</BoldText>
          <p>
            To change your PIN, you’ll have to do this at an ATM. You should
            have received the PIN automatically set up for you by Bó, via SMS
            (if you can’t find it, contact our Support Team via the app). Pop
            your Bó card into an ATM, input this PIN and then you should be able
            to change your PIN to any four digit code you wish. If you have any
            issues don’t hesitate to contact our Support Team in-app or via
            email.
          </p>
          <LineBreak />
          <BoldText>OTHER QUESTIONS</BoldText>
          <BoldText>How do I complain about Bó?</BoldText>
          <p>
            We're sorry that you've had a problem with Bó and we'll do
            everything we can to put it right. To complain, head to the
            'Support' tab in the app and type 'I want to raise a complaint' in
            the chat.
          </p>
          <BoldText>How do I report a death?</BoldText>
          <p>
            Please accept our condolences on your loss. If you could email{' '}
            <a href="mailto:bobereavements@natwest.com">
              bobereavements@natwest.com
            </a>{' '}
            with the account holder's name and address, we will make sure
            someone is in touch with you soon.
          </p>
        </TextContainer>
        <ImageContainer>
          <ImageContent>
            <Image imagePath="tea" />
          </ImageContent>
        </ImageContainer>
      </Wrapper>
    </Layout>
  );
};

export default FAQs;
